import { isBefore, sub } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useMeeting } from '../../../hooks/useMeeting';
import { setUser } from '../../../reduxToolkit/slices/commonSlice';
import {
  setIsAuthorized,
  setJoinState,
  setMeeting,
  setMeetingError,
  setPresetPassword,
  setShowBackToEvent,
  setShowDevicePreview,
  setShowKnock,
  setShowLogin,
  setShowPresentQuestion,
  setShowTechCheck,
  setVonageToken,
} from '../../../reduxToolkit/slices/videoSlice';
import { useSelector } from '../../../reduxToolkit/typedSelector';
import { getAuthData, getMeeting, joinMeeting, registerTemporary } from '../../../services/services';
import { isTokenSet, setToken } from '../../../utils/auth';
import { getQueryParam } from '../../../utils/functions';
import { useDevices } from './useDevices';
import { useMeetingPermission } from './useMeetingPermission';
import { useRecording } from './useRecording';
import { useBackgroundEffects } from './useVideoEffects';
import { setDefaultDeviceStates } from '../../../reduxToolkit/slices/deviceSlice';

export enum JOINSTATE {
  LOADING,
  DEVICE,
  AUTH, // Used for knock in oneOnOne
  PRESENTQUESTION,
  JOIN,
  LEAVE,
  ERROR,
}

//TODO get rid of passing state into handle function, set up routes of states for different meeting types and only call .next

export function useJoin() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const router = useRouter();
  const recording = useRecording();
  const permission = useMeetingPermission();
  const meetingHook = useMeeting();

  const meeting = useSelector(({ video }) => video.meeting);
  const user = useSelector(({ common }) => common.user);
  const joinState = useSelector(({ video }) => video.joinState);
  const session = useSelector(({ video }) => video.session);
  const videoEffects = useBackgroundEffects();

  const devices = useDevices();
  const audioInputDevice = useSelector(({ device }) => device.audioInputDevice);
  const videoInputDevice = useSelector(({ device }) => device.videoInputDevice);

  function getOrganizationId() {
    if (meeting?.meetingtype === 'studio') return meeting?.event?.organization._id.toString();
    else return meeting?.booth?.event?.organization._id.toString();
  }

  async function _getUser() {
    if (!isTokenSet()) return;
    if (user) return;

    try {
      const userData = await getAuthData();
      return userData.data.user;
    } catch (err) {
      console.error(err);
    }
  }

  async function _fetchAndSetVonageToken(password?: string, username?: string, joinToken?: string, viewerMode?: boolean, appointment?: string) {
    const joinResponse = await joinMeeting(meeting?._id!, username ?? user?.name!, password ?? '', joinToken, viewerMode, appointment);
    dispatch(setVonageToken(joinResponse.data?.token));
  }

  /// This checks for the parameter 'showpreview' in the URL and if it set to false, skips the preview component
  /// Reason for this: when accessing the preview component, the media stream is set to the according video element
  /// Said video element results in a taken media stream, even after unmount, causing problems while publishing the user
  async function _checkSkipPreview(): Promise<boolean> {
    const showPreview = getQueryParam('showpreview');
    if (showPreview === 'false') {
      const constraints = {
        audio: audioInputDevice?.deviceId === null ? false : { deviceId: audioInputDevice?.deviceId },
        video: videoInputDevice?.deviceId === null ? false : { deviceId: videoInputDevice?.deviceId, aspectRatio: 4 / 3 },
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(() => {
          devices
            .refresh()
            .then(() => devices.setInitials())
            .then(() => handleNext(JOINSTATE.AUTH, {}));
        })
        .catch(function (err) {
          console.error(err.name + ': ' + err.message);
        });

      return true;
    }
    return false;
  }

  function _showTechCheck(): boolean {
    const showTechCheck = getQueryParam('showtechcheck');
    if (showTechCheck === 'true') {
      dispatch(setShowTechCheck(true));
      return true;
    }
    return false;
  }

  async function _handleStudio(state: JOINSTATE, params: { password?: string; username?: string }) {
    switch (state) {
      case JOINSTATE.DEVICE:
        if ((await _checkSkipPreview()) || _showTechCheck()) return;
        dispatch(setShowDevicePreview(true));
        break;
      case JOINSTATE.AUTH:
        dispatch(setDefaultDeviceStates({ micOn: false, camOn: false }));
        dispatch(setShowDevicePreview(false));

        if (user && meeting?.event?.organization?._id === user.organization?._id) {
          handleNext(JOINSTATE.JOIN, {});
          break;
        }

        const queryPassword = getQueryParam('pw');
        if (queryPassword !== false) {
          dispatch(setPresetPassword(queryPassword));
        }

        dispatch(setShowLogin(true));
        dispatch(setShowDevicePreview(false));
        break;
      case JOINSTATE.JOIN:
        try {
          if (!user) {
            const randomEmail = `studioguest${Date.now().toString()}@gen.vystem.io`;
            const result = await registerTemporary(params.username!, randomEmail);
            setToken(result.access_token);
            dispatch(setUser(result.data));
          }

          await _fetchAndSetVonageToken(params.password, params.username);
          dispatch(setShowLogin(false));
          dispatch(setIsAuthorized(true));
          videoEffects.setStudioDefaultValues();
        } catch (err) {
          console.error(err);
          handleNext(JOINSTATE.ERROR, {});
        }
        break;
      case JOINSTATE.LEAVE:
        _leave();
        break;
      case JOINSTATE.LOADING:
      default:
        break;
    }
  }

  async function _handleOneOnOne(state: JOINSTATE, params: { joinToken?: string; appointment?: string }) {
    switch (state) {
      case JOINSTATE.DEVICE:
        if ((await _checkSkipPreview()) || _showTechCheck()) return;
        dispatch(setShowDevicePreview(true));
        break;
      case JOINSTATE.AUTH:
        dispatch(setShowDevicePreview(false));
        try {
          if (meeting?.booth?.organization?._id.toString() === user?.organization?._id.toString()) {
            handleNext(JOINSTATE.JOIN, {});
            return;
          }
        } catch (exc) {
          console.log(exc);
        }

        if (!user) {
          dispatch(setShowBackToEvent(true));
          return;
        }

        const joinToken = getQueryParam('jointoken');
        const appointment = getQueryParam('appointment');
        if (joinToken || appointment) {
          handleNext(JOINSTATE.JOIN, { joinToken: joinToken !== false ? joinToken : undefined, appointment: appointment !== false ? appointment : undefined });
          return;
        }

        if (!meeting?.settings?.usermustknock) {
          handleNext(JOINSTATE.JOIN, {});
          return;
        }
        dispatch(setShowKnock(true));
        break;
      case JOINSTATE.JOIN:
        try {
          await _fetchAndSetVonageToken(
            undefined,
            undefined,
            params.joinToken ? params.joinToken : undefined,
            undefined,
            params.appointment ? params.appointment : undefined
          );
          dispatch(setShowKnock(false));
          dispatch(setIsAuthorized(true));
        } catch (err) {
          console.error(err);
          handleNext(JOINSTATE.ERROR, {});
        }
        break;
      case JOINSTATE.LEAVE:
        _leave();
        break;
      case JOINSTATE.LOADING:
      default:
        break;
    }
  }

  async function _handleConference(state: JOINSTATE) {
    switch (state) {
      case JOINSTATE.DEVICE:
        if ((await _checkSkipPreview()) || _showTechCheck()) return;
        dispatch(setShowDevicePreview(true));
        break;
      case JOINSTATE.AUTH:
        dispatch(setShowDevicePreview(false));

        if (!user) {
          dispatch(setShowBackToEvent(true));
          return;
        } else if (meeting?.settings.usermustknock && !permission.isAdmin()) {
          dispatch(setShowKnock(true));
          return;
        }
        handleNext(JOINSTATE.JOIN, {});
        break;
      case JOINSTATE.JOIN:
        try {
          await _fetchAndSetVonageToken();
          dispatch(setShowKnock(false));
          dispatch(setIsAuthorized(true));
        } catch (err) {
          console.error(err);
          handleNext(JOINSTATE.ERROR, {});
        }
        break;
      case JOINSTATE.LEAVE:
        _leave();
        break;
      case JOINSTATE.LOADING:
      default:
        break;
    }
  }

  async function _handleNetworking(state: JOINSTATE) {
    switch (state) {
      case JOINSTATE.DEVICE:
        if ((await _checkSkipPreview()) || _showTechCheck()) return;
        dispatch(setShowDevicePreview(true));
        break;
      case JOINSTATE.AUTH:
        dispatch(setShowDevicePreview(false));

        if (!user) {
          dispatch(setShowBackToEvent(true));
          return;
        }

        if (meeting?.settings.usermustknock && !permission.isAdmin()) {
          dispatch(setShowKnock(true));
          break;
        } else {
          handleNext(JOINSTATE.JOIN, {});
        }
        break;
      case JOINSTATE.JOIN:
        try {
          await _fetchAndSetVonageToken();
          dispatch(setShowKnock(false));
          dispatch(setIsAuthorized(true));
        } catch (err) {
          console.error(err);
          handleNext(JOINSTATE.ERROR, {});
        }
        break;
      case JOINSTATE.LEAVE:
        _leave();
        break;
      case JOINSTATE.LOADING:
      default:
        break;
    }
  }

  async function _handlePresentation(state: JOINSTATE, params?: { password?: string; viewerMode?: boolean }) {
    switch (state) {
      case JOINSTATE.DEVICE:
        if ((await _checkSkipPreview()) || _showTechCheck()) return;
        if (
          meeting?.booth?.organization?._id.toString() === user?.organization?._id.toString() ||
          meeting?.booth?.event?.organization?._id === user?.organization?._id.toString()
        ) {
          dispatch(setShowDevicePreview(true));
        } else {
          handleNext(JOINSTATE.AUTH, {});
        }
        break;
      case JOINSTATE.AUTH:
        dispatch(setShowDevicePreview(false));
        if (
          meeting?.booth?.organization?._id.toString() === user?.organization?._id.toString() ||
          meeting?.booth?.event?.organization?._id === user?.organization?._id.toString()
        ) {
          handleNext(JOINSTATE.PRESENTQUESTION, {});
          return;
        }

        if (!meeting?.settings?.usesPassword) {
          handleNext(JOINSTATE.JOIN, {});
          return;
        }
        if (!user) {
          dispatch(setShowBackToEvent(true));
          return;
        }

        dispatch(setShowLogin(true));
        break;
      case JOINSTATE.PRESENTQUESTION:
        dispatch(setShowPresentQuestion(true));
        break;
      case JOINSTATE.JOIN:
        try {
          if (
            meeting?.meetingtype === 'presentation' &&
            meeting?.booth?.event?.settings?.presentationCountdownEnabled &&
            !permission.isAdmin() &&
            // Check if countdown is still running
            isBefore(Date.now(), sub(new Date(meeting?.slot.datetime_start!), { minutes: meeting?.booth.event.settings.presentations.blockTime }))
          ) {
            router.push(`/videochat/waitingroom/${meeting?._id}`);
            return;
          }
          await _fetchAndSetVonageToken(params?.password, undefined, undefined, params?.viewerMode);
          dispatch(setShowLogin(false));
          dispatch(setIsAuthorized(true));
        } catch (err) {
          console.error(err);
          handleNext(JOINSTATE.ERROR, {});
        }
        break;
      case JOINSTATE.LEAVE:
        _leave();
        break;
      case JOINSTATE.LOADING:
      default:
        break;
    }
  }

  async function _handleTemporary(state: JOINSTATE, params: { username?: string; email?: string }) {
    switch (state) {
      case JOINSTATE.DEVICE:
        if ((await _checkSkipPreview()) || _showTechCheck()) return;
        dispatch(setShowDevicePreview(true));
        break;
      case JOINSTATE.AUTH:
        dispatch(setShowDevicePreview(false));
        if (user) {
          await handleNext(JOINSTATE.JOIN, {});
          return;
        }
        await handleNext(JOINSTATE.JOIN, { username: t('meeting:temporary.user'), email: Math.random().toString(36).substr(2, 5) + 'temp' + '@events.vystem' });
        break;
      case JOINSTATE.JOIN:
        try {
          if (!user) {
            const result = await registerTemporary(params.username!, params.email!);
            setToken(result.access_token);
            dispatch(setUser(result.data));
          }

          await _fetchAndSetVonageToken(undefined, params.username ?? user?.name);
          dispatch(setShowLogin(false));
          dispatch(setIsAuthorized(true));
        } catch (err) {
          console.error(err);
          handleNext(JOINSTATE.ERROR, {});
        }
        break;
      case JOINSTATE.LEAVE:
        _leave();
        break;
      case JOINSTATE.LOADING:
      default:
        break;
    }
  }

  async function _handleBreakout(state: JOINSTATE) {
    switch (state) {
      case JOINSTATE.DEVICE:
        if ((await _checkSkipPreview()) || _showTechCheck()) return;
        dispatch(setShowDevicePreview(true));
        break;
      case JOINSTATE.AUTH:
        dispatch(setShowDevicePreview(false));
        if (user) {
          await handleNext(JOINSTATE.JOIN, {});
          return;
        }
        dispatch(setShowLogin(true));
        break;
      case JOINSTATE.JOIN:
        try {
          await _fetchAndSetVonageToken(undefined, user?.name);
          dispatch(setShowLogin(false));
          dispatch(setIsAuthorized(true));
        } catch (err) {
          console.error(err);
          handleNext(JOINSTATE.ERROR, {});
        }
        break;
      case JOINSTATE.LEAVE:
        meetingHook.redirectToRoom(undefined, meeting?.parentMeetingId, undefined, undefined, true, false, false);
        break;
      case JOINSTATE.LOADING:
      default:
        break;
    }
  }

  async function _handleCommon(meetingId: string) {
    let curMeeting,
      curUser = undefined;
    if (!meeting) {
      try {
        curMeeting = await getMeeting(meetingId);
        if (!curMeeting) dispatch(setMeetingError('meeting:errors.loading.wrong-id'));
      } catch (err) {
        dispatch(setMeetingError('meeting:errors.loading.wrong-id'));
        return;
      }
    }

    if (!user) {
      curUser = await _getUser();
    } else {
      curUser = user;
    }

    // Studios can be joined without being registered
    if (curMeeting && curMeeting.meetingtype !== 'studio' && curMeeting.meetingtype !== 'temporary' && !curUser) {
      dispatch(setMeetingError('meeting:errors.loading.no-user'));
      return;
    }

    dispatch(setMeeting(curMeeting));
    dispatch(setUser(curUser!));
  }

  async function _leave() {
    const userOrg = user?.organization?._id ?? user?.organization ?? undefined;

    const redirectToken = getQueryParam('redirect');
    if (userOrg && getOrganizationId() === userOrg) {
      if (redirectToken === false) window.location.href = '/account';
      else window.location.href = window.location.origin + atob(String(redirectToken));
    } else if (meeting?.meetingtype === 'temporary') {
      if (redirectToken === false) window.location.href = '/meet';
      else window.location.href = window.location.origin + atob(String(redirectToken));
    } else if (redirectToken !== false) {
      window.location.href = window.location.origin + atob(String(redirectToken));
    } else {
      const slug = meeting?.booth?.event?.slug ?? meeting?.event?.slug ?? undefined;
      window.location.href = `/event/${slug}/live`;
    }
  }

  function _stepCanProceed(parameterState: JOINSTATE): boolean {
    if (joinState > parameterState) {
      console.log('Parameter state is lower than current, skipping');
      return false;
    }
    return true;
  }
  async function handleNext(
    state: JOINSTATE,
    params: { meetingid?: string; password?: string; username?: string; joinToken?: string; viewerMode?: boolean; email?: string; appointment?: string }
  ) {
    console.log(`handleNext, state ${state}`);

    if (!_stepCanProceed(state)) return;

    if (state === JOINSTATE.LOADING) {
      _handleCommon(params.meetingid!);
      return;
    } else if (state === JOINSTATE.ERROR) {
      dispatch(setShowBackToEvent(true));
      return;
    }

    dispatch(setJoinState(state));

    console.log(`handling state ${state} for type ${meeting?.meetingtype}`);

    const meetingType = meeting?.meetingtype;

    switch (meetingType) {
      case 'studio':
        _handleStudio(state, params);
        break;
      case 'meeting':
        _handleOneOnOne(state, params);
        break;
      case 'presentation':
        _handlePresentation(state, params);
        break;
      case 'temporary':
        _handleTemporary(state, params);
        break;
      case 'conference':
        _handleConference(state);
        break;
      case 'networking':
        _handleNetworking(state);
        break;
      case 'breakout':
        _handleBreakout(state);
        break;
      default:
        console.log('no meetingtype detected');
        break;
    }
  }

  return { handleNext };
}
